<template>
  <div class="logout">
    <v-container>
      <v-row>
        <v-col>
          <h2>Logout</h2>
          <LogoutComponent />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import LogoutComponent from '@/components/auth/LogoutComponent.vue';

export default {
  name: 'Logout',
  props: {
    user: String,
  },
  metaInfo: {
    title: 'Logout',
  },
  components: {
    LogoutComponent,
  },
};
</script>
