<template>
  <div class="logout">
    <v-btn color="orange" @click="logOut">Logout</v-btn>

    <v-snackbar v-model="error">
      <v-icon>mdi-alert-circle-outline</v-icon>
      {{ message }}
    </v-snackbar>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
  name: 'Logout',
  data() {
    return {
      error: false,
      message: '',
    };
  },
  methods: {
    async logOut() {
      try {
        // Clear the session
        sessionStorage.clear();

        // Signout
        await Auth.signOut();

        // Clear the user
        this.$store.commit('user', null);
        this.$store.commit('newKratosUser', null);
        this.$store.commit('client', null);
        this.$store.commit('portalUser', null);
        this.$store.commit('isAdmin', null);
        this.$store.commit('portalAccess', null);
        this.$store.commit('reportAccess', null);
        this.$store.commit('apiAccess', null);
        this.$store.commit('activityAccess', null);
        this.$store.commit('bookSnapshotAccess', null);

        // Go to login
        this.$router.push('/login');
      } catch (error) {
        console.log('error signing out: ', error); // eslint-disable-line
      }
    },
    mounted() {
      Auth.signOut();
    },
  },
};
</script>
